import { render, staticRenderFns } from "./TabProductDetailPanel.vue?vue&type=template&id=43f74d6e&scoped=true&"
import script from "./TabProductDetailPanel.vue?vue&type=script&lang=js&"
export * from "./TabProductDetailPanel.vue?vue&type=script&lang=js&"
import style0 from "./TabProductDetailPanel.vue?vue&type=style&index=0&id=43f74d6e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43f74d6e",
  null
  
)

export default component.exports