import { render, staticRenderFns } from "./PreviewBox.vue?vue&type=template&id=8a10b63a&scoped=true&"
import script from "./PreviewBox.vue?vue&type=script&lang=js&"
export * from "./PreviewBox.vue?vue&type=script&lang=js&"
import style0 from "./PreviewBox.vue?vue&type=style&index=0&id=8a10b63a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a10b63a",
  null
  
)

export default component.exports